import { MapPin, Droplet, Leaf } from "lucide-react";

 import React from "react";
const icons = {
  location: MapPin,
  water: Droplet, 
  crop: Leaf,
};

export default function WhatsAround({data}) {
  const cards = [
    {
      type: "location",
      title: data[0]?.what_around,
      class:'lightgreen',
      content: "",
    },
    {
      type: "water",
      title: "Water Source ",
      class:'lightgreen',
      content: "Canal",
    },
    {
      type: "crop",
      title: "Crop Suits for ",
      class:'lightgreen',
      content: "SugarCane",
    },
    {
      type: "crop",
      title: "Crop Suits for  ",
      class:'lightgreen',
      content: "SugarCane",
    },
    {
      type: "location",
      title: data[0]?.propertyAddress,
      class:'lightgreen',
      content: "",
    },
    {
      type: "water",
      title: "Water Source  ",
      class:'lightgreen',
      content: "Canal",
    },
    {
      type: "crop",
      title: "Crop Suits for ",
      class:'lightgreen',
      content: "SugarCane",
    },
    {
      type: "crop",
      title: "Crop Suits for  ",
      class:'lightgreen',
      content: "SugarCane",
    },
  ];

  return (
    <div className="container my-5">
      <div className="row g-4">
        {data[0]?.what_around?.map((card, index) => {
          const Icon = icons[cards[index].type];
          return (
            <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-3 my-5">
              <div className="position-relative text-center d-flex justify-content-center">
                <div
                  className="position-absolute bg-drak-green top-0 start-50 translate-middle rounded-circle bg-light p-3"
                  style={{ transform: "translate(-50%, -50%)" ,background:'#558B2F'}}
                >
                  <Icon className="h-5 w-5 text-light" />
                </div>
                <div className={`details-card card text-white ${cards[index].class}  p-3 pt-4 text-dark`} style={{ width:'305px',height:'82px'}}>
                  <div className="card-body">
                    {/* <h6 className="card-title mb-2">{card.title}</h6>/ */}
                    <p className="card-text mb-0 text-capitalize"  style={{color:' #5F705F'}}>{card?.name}:{card?.duration}</p>
                   
                 
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
