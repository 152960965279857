import { MapPin, Droplet, Leaf } from "lucide-react";

 import React from "react";
const icons = {
  location: MapPin,
  water: Droplet, 
  crop: Leaf,
};

export default function InfoCards({data}) {
  const cards = [
    {
      type: "location",
      title: data[0]?.propertyAddress,
      class:'dark-green',
      content: "",
    },
    {
      type: "water",
      title: "Water Source :",
      class:'light-green',
      content: data[0]?.Water_Source,
    },
    {
      type: "crop",
      title: "Crop Suits for :",
      class:'dark-green',
      content: data[0]?.Crop_Suits_for,
    },
  ];

  return (
    <div className="container my-5">
      <div className="row g-4 justify-content-around">
        {cards.map((card, index) => {
          const Icon = icons[card.type];
          return (
            <div key={index} className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-3">
              <div className="position-relative text-center">
                <div
                  className="position-absolute bg-light-green top-0 start-50 translate-middle rounded-circle bg-light p-3"
                  style={{ transform: "translate(-50%, -50%)" }}
                >
                  <Icon className="h-5 w-5 text-success" />
                </div>
                <div className={`details-card card text-white ${card.class}  p-3 pt-5`}>
                  <div className="card-body">
                    {/* <h6 className="card-title mb-2">{card.title}</h6>/ */}
                    <p className="card-text mb-0 text-capitalize">{card.title}</p>
                    <p className="card-text mb-0 text-capitalize">{card.content}</p>
                 
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
