import React, { useEffect, useState } from 'react'
import Navbar from '../../components/NavBar'
import Footer from '../../components/Footer/footer'
import AdsCard from './land-details/AdsCard/AdsCard'

const AgriculturalLand = () => {

const [Agricultural,setAgricultural]=useState([])

const getData = async ()=>{
    try {
        
       const res= await fetch(`https://backend.myplotpic.com/api/getAdvertisement`, {
            method: "GET",
            // body: new URLSearchParams(body),
          })

          const reson =await res.json()
          const filtertypeProperty = reson.data.filter((i)=>i.typeProperty === 'Agriculture Land'  ) 
          console.log(filtertypeProperty);
          setAgricultural(filtertypeProperty)
    } catch (error) {
        
    }
}

useEffect(()=>{
    getData()
},[])
  return (
    <div>
          <Navbar />
 
{
    Agricultural.map((item,i)=>(
        
         <AdsCard key={i} data={item}></AdsCard>
    ))
}

 <Footer />
    </div>
  )
}

export default AgriculturalLand