import React, { useEffect, useState } from 'react'
import './landStyle.css'
import MapComponent from '../mapComponent.js'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import InfoCards from './InfoCards.jsx'
import bgicon from './Vector.svg'
import bgicon2 from './Vector2.svg'
import imagesvg from './Rectangle 562.png'
import WhatsAround from './WhatsAround.jsx'
import imgdiv from './divSVG.svg'
import VideoGallery from '../VideoGallery/VideoGallery.jsx'
import BookingPlot from '../BookingPlot/BookingPlot.jsx'
import icoon from './file-1 1.svg'
const LandDetails = () => {
    var endpoint = "https://backend.myplotpic.com/uploads/";
const {id}=useParams()
const [Agricultural,setAgricultural]=useState([{files:[],youtubeLink:[]}])
const obj={
  Water_Source:"",
  Crop_Suits_for:'',
  Land_Width:'',
  Land_Length:'',
  Land_Road:"",
  land_type:'',
  land_soil:'',
  what_around:[{name:'',duration:''}],
  youtubeLink:['','',''],
  land_status:'',
  Expected_yearl_Lease:'',
  Starting_Price:'',
  Urbanization_Possibility:''
}
console.log(id);

const getData = async ()=>{
    try {
        
       const res= await fetch(`https://backend.myplotpic.com/api/getAdvertisement`, {
            method: "GET",
            // body: new URLSearchParams(body),
          })

          const reson =await res.json()
          const filtertypeProperty = reson.data.filter((i)=>i.typeProperty === 'Agriculture Land' && i.adId===id ) 
          console.log(filtertypeProperty);
          setAgricultural(filtertypeProperty)
    } catch (error) {
        
    }
}

const land=  JSON.parse(window.localStorage.getItem("json"));
useEffect(()=>{

 

getData()
     
},[])

  return (
    <div className='agricultrural-container'>
        <div className='agri-header'>
 <h1 className='text-center'>
 { Agricultural[0]?.adTitle }
 </h1>
        </div>

      <div className='agri-map'>
{Agricultural[0]?.lat &&  <MapComponent  data={Agricultural} />}
        </div>

 
<div>
    <InfoCards data={Agricultural}/>
</div>


<div className='agri-Explore'>
<div  className='Explore1'>
    <h1>
    Explore Our Agricultural Land
    </h1>
</div>
    <div className='row container mx-auto'>

        <div className="col-sm-12 col-lg-3 align-items-center   justify-content-center  d-none d-lg-flex flex-column flex-wrap">
<div className='cilp-pill d-flex px-0'>
<div className='aaaa'>
    <img src={bgicon} alt="" />
    <p style={{
        position:'absolute'
    }}>{Agricultural[0]?.Land_Width ||150}</p>
</div>
<div className='pera-1'>
<p>Width in ft </p>
</div>
</div>
<div >
<img src={imagesvg} alt="img" />
</div>

<div className='cilp-pill d-flex px-0'>
<div className='aaaa'>
    <img src={bgicon} alt="" />
    <p style={{
        position:'absolute'
    }}>{Agricultural[0]?.Land_Road||45}</p>
</div>
<div className='pera-1'>
<p>Road in ft </p>
</div>
</div>
        </div>
        <div className="col-sm-12 col-lg-6">


        <div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">

  {Agricultural[0]?.files.map((slideImage, index) => (

<div class="carousel-item active">
<img src={endpoint + slideImage} class="d-block w-100" alt="..."/>
</div> 
                      ))}
     
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


        </div>
        <div className="col-sm-12 col-lg-3 row align-items-center justify-content-center flex-column my-3 gap-3 d-md-flex d-lg-none">
<div className='cilp-pill d-flex px-0'>
<div className='aaaa'>
    <img src={bgicon} alt="" />
    <p style={{
        position:'absolute'
    }}>{Agricultural[0]?.Land_Width ||150}</p>
</div>
<div className='pera-1'>
<p>Width in ft </p>
</div>
</div>
 

<div className='cilp-pill d-flex px-0'>
<div className='aaaa'>
    <img src={bgicon} alt="" />
    <p style={{
        position:'absolute'
    }}>{Agricultural[0]?.Land_Road||45}</p>
</div>
<div className='pera-1'>
<p>Road in ft </p>
</div>
</div>
        </div>
        <div className="col-sm-12 col-lg-3 d-flex align-items-center justify-content-center flex-column mb-3 gap-3">
<div className='cilp-pill d-flex px-0' style={{
    borderRadius:'25px 0px 0px 25px',
    
   
}}>

<div className='pera-1'>
<p>Length in ft  </p>
</div>
<div className='aaaa'>
    <img src={bgicon2} alt="" />
    <p style={{
        position:'absolute', right:'10%',left:'unset'
    }}>{Agricultural[0]?.Land_Length}</p>
</div>
</div>
<div className='d-none d-lg-block'>
<img style={{
    transform: "scaleX(-1)",
  }} src={imagesvg} alt="img" />
</div>

<div className='cilp-pill d-flex px-0' style={{
    borderRadius:'25px 0px 0px 25px'
}} >

<div className='pera-1'>
<p>Type of land </p>
</div>
<div className='aaaa'>
    <img src={bgicon2} alt="" />
    <p style={{
        position:'absolute', right:'10%',left:'unset'
    }}>{Agricultural[0]?.land_type}</p>
</div>
</div>
        </div>
       
       

    </div>
    <div className='col-12-bottom'>
        <div className='cilp-pill d-flex px-0'>
<div className='aaaa'>
    <img src={bgicon} alt="" />
    <p style={{
        position:'absolute'
    }}>{Agricultural[0]?.land_soil}</p>
</div>
<div className='pera-1'>
<p>Type of soil </p>
</div>
</div>
        </div>
</div>


<div className='agri-WhatsAround'>

<WhatsAround data={Agricultural}/>

</div>


<div className='agir-Documents pt-5'>


    <div className='row justify-content-center align-item-center w-100'>
        <div className='col-sm-12 col-md-12 col-xl-3 sub-2-div position-relative'>
<img src={imgdiv} alt="img" />
<p style={{
        position: 'absolute',
        left: '28%',
        top: '144px',
        color: 'red',
        fontFamily: 'Inria Serif',
        fontSize: '23px',
        fontWeight: '700',
        lineHeight: '27.58px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        
}}>
{Agricultural[0]?.Expected_yearl_Lease}
</p>

<h6 style={{
        position: 'absolute',
        bottom: '70px',
        left: '28px',
        fontFamily: 'Inria Serif',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '23.98px',
        textAlign: 'center',
       
        color: 'white',
}}>Expected yearly Lease</h6>
        </div>
        <div className='col-sm-12 col-md-12 col-xl-3 sub-2-div' style={{
            position:'relative',top:'-70px'
        }}>
        <img src={imgdiv} alt="img" />
        
        <p style={{
        position: 'absolute',
        left: '28%',
        top: '144px',
        color: 'red',
        fontFamily: 'Inria Serif',
        fontSize: '23px',
        fontWeight: '700',
        lineHeight: '27.58px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        
}}>
₹{Agricultural[0]?.Starting_Price}/-
</p>

<h6 style={{
        position: 'absolute',
        bottom: '70px',
        left: '28px',
        fontFamily: 'Inria Serif',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '23.98px',
        textAlign: 'center',
       
        color: 'white',
}}>Starting Price</h6>
        </div>
        <div className='col-sm-12 col-md-12 col-xl-3  sub-2-div position-relative'>
        <img src={imgdiv} alt="img" />
      
        <p style={{
        position: 'absolute',
        left: '28%',
        top: '144px',
        color: 'red',
        fontFamily: 'Inria Serif',
        fontSize: '23px',
        fontWeight: '700',
        lineHeight: '27.58px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        
}}>
{Agricultural[0]?.Urbanization_Possibility}
</p>

<h6 style={{
        position: 'absolute',
        bottom: '70px',
        left: '28px',
        fontFamily: 'Inria Serif',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '23.98px',
        textAlign: 'center',
       
        color: 'white',
}}>Urbanization Possibility</h6>
        </div>

    </div>



    <div className="row justify-content-center g-4 w-100">
        <div className='col-12 '>
            <h1 className='text-white'>Documents</h1>
        </div>
          {Agricultural[0]?.documentSchema?.map((doc,i) => (
            <div key={i} className="  col-sm-12 col-md-6 col-lg-4 col-xl-3 row justify-content-center my-3 ">
              <div className="card  doc-item">
                <div className="card-body d-flex align-items-center gap-2 p-0 justify-content-start">
                  <div
                    className={`d-flex justify-content-center align-items-center rounded-circle `}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <img src={icoon} alt='test' />
                  </div>
                 <a href={`https://backend.myplotpic.com/${doc.file}`} target="_blank"> <span className="" style={{fontSize:'20px',color:'black'}}>{doc?.name} </span></a>
                </div>
              </div>
            </div>
          ))}
        </div>
</div>

 

<div>
    <VideoGallery data={Agricultural}/>
</div>
 
<div>
    <BookingPlot data={Agricultural[0]}/>
</div>








<section class="container mx-auto p-4">
  <div class="mb-4">
    <h2 class="h4  Additional-title text-dark font-weight-bold">
      Additional Info Title
    </h2>
    <div class=" prag-add mt-3">
      <p>
        Our team will get back to you promptly
      </p>
      <p>
        with tailored solutions to meet your needs.
      </p>
      <p>
        Let us help you take the first step toward
        <br />
        your agricultural goals!
      </p>
    </div>
  </div>
  
</section>
<div style={{
    background:'#395203',
    fontSize:'20px'
}} class="mt-4 text-white p-3 text-center">
    Status: Lease available
  </div>





 
    </div>
  )
}

export default LandDetails